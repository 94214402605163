import request from '@/utils/request'


// 查询新-用户微博列表
export function listBlog(query) {
  return request({
    url: '/cigarette/user-blog/list',
    method: 'get',
    params: query
  })
}

// 查询新-用户微博分页
export function pageBlog(query) {
  return request({
    url: '/cigarette/user-blog/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户微博详细
export function getBlog(data) {
  return request({
    url: '/cigarette/user-blog/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户微博
export function addBlog(data) {
  return request({
    url: '/cigarette/user-blog/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户微博
export function updateBlog(data) {
  return request({
    url: '/cigarette/user-blog/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户微博
export function delBlog(data) {
  return request({
    url: '/cigarette/user-blog/delete',
    method: 'post',
    data: data
  })
}
