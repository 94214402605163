<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="b端生产用户id关联 {zb_user.id}平台为：0,b端用户id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入b端生产用户id关联 {zb_user.id}平台为：0,b端用户id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Blog Title:" prop="title" >
        <a-input v-model="form.title" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.title && form.title.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Blog Keywords:" prop="keywords" >
        <a-input v-model="form.keywords" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.keywords && form.keywords.length) || 0) }}/100, Separate multiple keywords with commas (,)</div>

      </a-form-model-item>
      <a-form-model-item label="Blog Description:" prop="description" >
        <a-input v-model="form.description" placeholder="" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="Brand" prop="businessType">
        <a-select
          placeholder="Brand"
          style="width: 100%"
          showSearch
          mode="multiple"
          v-model="form.sendType"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in brandList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="Business Type:" prop="businessType" >-->
<!--        <a-checkbox-group v-model="form.businessType">-->
<!--          <a-checkbox :value="item.id" v-for="(item,index) in BusinessTypeList" :key="index">-->
<!--            {{item.name }}-->
<!--          </a-checkbox>-->
<!--        </a-checkbox-group>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Blog text:" prop="blogText" >
<!--        <a-input v-model="form.blogText" placeholder="Please enter blog text" type="textarea" allow-clear />-->
        <editor v-model="form.blogText" />
        <div style="display: flex;justify-content: flex-end">Word Count:{{ ((form.blogText && form.blogText.length) || 0) }}</div>,
      </a-form-model-item>

      <a-form-model-item label="Cover Picture:" prop="url" >
<!--        <a-input v-model="form.url" placeholder="" allow-clear />-->
<!--        <div style="display: flex;justify-content: flex-end">Recommended size of picture:900 pixels * 1100 pixels, supporting JPG and PNG formats.</div>-->
        <a-upload
          name="file"
          :multiple="true"
          :show-upload-list="false"
          :action="tupianshangchuanDizhi"
          :headers="headers"
          @change="handleChange($event)"
        >
          <a-button type="primary" style="margin-right: 10px;">Upload Picture</a-button>Recommended size of picture:900 pixels * 1100 pixels, supporting JPG and PNG formats.
        </a-upload>

        <div style="display:flex;flex-wrap: wrap;margin-top: 10px;" v-if="form.url">
          <div style="display: flex;align-items: center; justify-content: center">
            <img :src="form.url"  style="width: 200px;height: 200px;" />
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="Blog Status:" prop="status" >
        <a-radio-group v-model="form.status">
          <a-radio :value="1">
            Enable
          </a-radio>
          <a-radio :value="0">
            Disable
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBlog, addBlog, updateBlog } from '@/api/cigarette/blog'
import {listConfig} from "@/api/cigarette/config";
import {listBrand} from "@/api/cigarette/brand";
import {tupianshangchuanDizhi} from "@/utils/request";
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        title: null,

        keywords: null,

        description: null,

        businessType: null,

        blogText: null,

        sendType: [],

        url: null,

        status: 1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          { required: true, message: 'Blog Title cannot be empty', trigger: 'blur' }
        ],
        blogText: [
          {
            required: true, message: 'Blog text cannot be empty', trigger: 'blur'
          }
        ]
      },
      brandList: [],
      headers:{}
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.getListBusinessType();
    this.getBrandList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      this.form.url = e.file.response.data
      // e.file.response.data
    },
    getBrandList() {
      listBrand().then(res => {
        if (res.success) {
          this.brandList = res.data;
        }
      })
    },
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        title: null,
        keywords: null,
        description: null,
        businessType: null,
        blogText: null,
        sendType: [],
        url: null,
        status: 1
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Add'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBlog({"id":id}).then(response => {
        this.form = response.data
        if(this.form.sendType == 0) {
          this.form.sendType = []
        } else {
          this.form.sendType = this.form.sendType.split(',')
        }
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if(this.form.sendType.length === 0) {
            this.form.sendType = 0;
          } else {
            this.form.sendType = this.form.sendType.join(',');
          }
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBlog(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBlog(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
